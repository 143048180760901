const Volunteering = () => {
  
  const images = [
    `${process.env.PUBLIC_URL}/assets/volunteering.jpg`,
    `${process.env.PUBLIC_URL}/assets/Haxtreme.jpg`,
    `${process.env.PUBLIC_URL}/assets/Mehewara.jpg`,
    `${process.env.PUBLIC_URL}/assets/about.jpg`
    
  ];
  

  const scrollingImages = [...images];

  return (
    <div className="volunteering-page">
      <h1>Volunteering</h1>
      <div className="volunteering-content">
        {/* Scrolling Gallery Block */}
        <div className="gallery-block">
          <h2>Volunteering Gallery</h2>
          <div className="gallery-container">
            <div className="image-scroll">
              {scrollingImages.map((image, index) => (
                <img src={image} alt={`Gallery ${index +1}`} key={index} className="gallery-image" />
              ))}
            </div>
          </div>
        </div>

        {/* Description Block */}
        <div className="description-block">
          <h2>About My Volunteering</h2>
          <p>
          I am passionate about making a difference through volunteering, and I’ve been privileged to contribute to several impactful initiatives. As a Program Team Member for the HaXtreme Competition, I helped organize a highly successful event that received excellent feedback from participants. Additionally, I volunteered with the Engineering Council Sri Lanka (ECSL) as part of the Design Team to create an Electrical Guidance Book.


          </p>
          <p>
          Currently, I am serving as the Galle District Coordinator for the Mehewara initiative, where we organize O/L Mathematics seminars for underprivileged schools. 
          </p>
        </div>
      </div>
    </div>
  );
};

export default Volunteering;
