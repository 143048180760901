

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-media">
        <a href="https://www.facebook.com/pathum.vimukthi.75?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="social-icon">
          <img src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" alt="Facebook" />
        </a>
        <a href="https://github.com/Pathum-Vimukthi-Kumara" target="_blank" rel="noopener noreferrer" className="social-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white">
        <path d="M12 0c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.207 11.387.6.111.793-.261.793-.577v-2.17c-3.338.727-4.033-1.415-4.033-1.415-.546-1.387-1.333-1.757-1.333-1.757-1.089-.744.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.835 2.809 1.305 3.495.998.108-.775.418-1.305.762-1.605-2.665-.303-5.466-1.333-5.466-5.933 0-1.312.469-2.382 1.235-3.22-.123-.303-.535-1.526.117-3.176 0 0 1.008-.322 3.301 1.23a11.474 11.474 0 0 1 3.003-.404 11.474 11.474 0 0 1 3.003.404c2.292-1.552 3.299-1.23 3.299-1.23.653 1.65.241 2.873.118 3.176.768.838 1.235 1.908 1.235 3.22 0 4.61-2.804 5.625-5.475 5.922.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.577 4.77-1.587 8.207-6.084 8.207-11.387 0-6.627-5.373-12-12-12z"/>
     </svg>
     </a>
         <a href="https://www.linkedin.com/in/pathum-vimukthi-635740277" target="_blank" rel="noopener noreferrer" className="social-icon">
          <img src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Linkedin_icon.svg" alt="LinkedIn" />
        </a>
      </div>
      <p>© 2024 |Pathum Vimukthi.</p>
    </footer>
  );
};

export default Footer;
