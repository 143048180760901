// src/components/Projects.js
import React from 'react';
import './Project.css';

const projects = [
  {
    title: 'Project 1',
    image: `${process.env.PUBLIC_URL}/assets/WordPress.png`,
    description: 'WordPress Portfolio Website.',
  },
  {
    title: 'Project 2',
    image: `${process.env.PUBLIC_URL}/assets/new.png`,
    description: 'React Portfolio Website.',
  },
  
  
];

const Projects = () => {
  return (
    <div className="projects-page">
      <h1>My Projects</h1>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div className="project-block" key={index}>
            <img src={project.image} alt={project.title} className="project-image" />
            <h3 className="project-title">{project.title}</h3>
            <p className="project-description">{project.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
