// src/components/Header.js
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  // Close the dropdown if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      {/* Original Navbar for Desktop */}
      <div className="header">
        <ul className="nav-links">
          <li>
            <Link to="/" className="nav-link">
              <i className="fas fa-home"></i> <span>Home</span>
            </Link>
          </li>
          <li>
            <Link to="/about" className="nav-link">
              <i className="fas fa-user"></i> <span>About</span>
            </Link>
          </li>
          <li>
            <Link to="/certificate" className="nav-link">
              <i className="fas fa-certificate"></i> <span>Certificates</span>
            </Link>
          </li>
          <li>
            <Link to="/project" className="nav-link">
              <i className="fas fa-folder-open"></i> <span>Projects</span>
            </Link>
          </li>
          <li>
            <Link to="/skills" className="nav-link">
              <i className="fas fa-tools"></i> <span>Skills</span>
            </Link>
          </li>
          <li>
            <Link to="/volunteering" className="nav-link">
              <i className="fas fa-hands-helping"></i> <span>Volunteering</span>
            </Link>
          </li>
        </ul>
      </div>

      {/* Dropdown Menu for Mobile */}
      <div className="dropdown-container" ref={dropdownRef}>
        <button id="menuButton" onClick={toggleDropdown}>☰</button>
        {isOpen && (
          <div className="dropdown-content">
            <Link to="/" className="link" title="Home" onClick={closeDropdown}>Home</Link>
            <Link to="/about" className="link" title="About" onClick={closeDropdown}>About</Link>
            <Link to="/certificate" className="link" title="Certificates" onClick={closeDropdown}>Certificates</Link>
            <Link to="/project" className="link" title="Projects" onClick={closeDropdown}>Projects</Link>
            <Link to="/skills" className="link" title="Skills" onClick={closeDropdown}>Skills</Link>
            <Link to="/volunteering" className="link" title="Volunteering" onClick={closeDropdown}>Volunteering</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
