import React from 'react';
import './Skills.css';

const skills = [
  { name: 'HTML', percentage: 70, icon: 'html' },
  { name: 'JavaScript', percentage: 30, icon: 'javascript' },
  { name: 'CSS', percentage: 70, icon: 'css' },
  { name: 'Python', percentage: 30, icon: 'python' },
  { name: 'React', percentage: 30, icon: 'react' },
  { name: 'C', percentage: 50, icon: 'c' },
  { name: 'C++', percentage: 60, icon: 'cpp' },
  { name: 'GitHub', percentage: 30, icon: 'github' },
];

const getSkillLevel = (percentage) => {
  if (percentage <= 35) return 'Beginner';
  if (percentage <= 50) return 'Intermediate';
  if (percentage <= 75) return 'Advanced';
  return 'Expert';
};

const Skills = () => {
  return (
    <div className="skills-page">
      <h1>My Skills</h1>
      <div className="skills-container">
        {skills.map((skill, index) => (
          <div className="skill" key={index}>
            <img
              src={`https://skillicons.dev/icons?i=${skill.icon}`}
              alt={skill.name}
              className="skill-icon"
            />
            <p>{skill.name}</p>
            <div className="progress-bar-container">
              <div className="progress-bar" style={{ width: `${skill.percentage}%` }}>
                <span className="progress-text">{getSkillLevel(skill.percentage)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
