import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Typed from 'typed.js'; // Import Typed from typed.js
import './Home.css';

const Home = () => {
  const textRef = useRef(null);

  useEffect(() => {
    const typed = new Typed(textRef.current, {
      strings: ["Pathum Vimukthi", "Engineer", "Frontend Developer", "Web Developer"],
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 2000,
      loop: true
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="home-page">
      {/* Hero Section */}
      <div className="hero-container">
        <div className="hero-content">
          {/* Profile Image */}
          <div className="profile-photo-wrapper">
            <img
              src={`${process.env.PUBLIC_URL}/assets/pathum3.jpg`}
              alt="Pathum Vimukthi"
              className="profile-photo"
            />
          </div>
          {/* Introduction */}
          <div className="hero-text">
            <h1>
              I'm <span ref={textRef} className="highlight"></span>
            </h1>
            <h2>Computer Engineering Student</h2>
            <p>
              👋 Hi, I'm passionate about software engineering and problem-solving. Currently pursuing Computer Engineering at the Faculty of Engineering, University of Ruhuna.
            </p>
            {/* Action Buttons */}
            <div className="action-buttons">
              <Link to="/about" className="btn-primary">
                More About Me
              </Link>
              <a
                href="https://github.com/Pathum-Vimukthi-Kumara" 
                target="_blank"
                rel="noopener noreferrer"
                className="btn-secondary"
              >
                GitHub
              </a>
              <a
                href="http://www.linkedin.com/in/pathum-vimukthi-635740277" 
                target="_blank"
                rel="noopener noreferrer"
                className="btn-secondary"
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;